import Vue from "vue";
import Vuex from "vuex";
import VueRouter, { RouteConfig } from "vue-router";
import Store from "@/store/index";
import { Watch } from "vue-property-decorator";

Vue.use(VueRouter);

// @ts-ignore
const routes: Array<RouteConfig> = [
  {
    path: "/",
    name: "home",
    component: () =>
      import(/* webpackChunkName: "about" */ "@/views/homePage/index.vue"),
    children: [],
  },
  {
    path: "/home",
    name: "home",
    component: () =>
      import(/* webpackChunkName: "about" */ "@/views/homePage/index.vue"),
    children: [],
  },
  {
    path: "/system/admin",
    name: "system_admin",
    component: () => import(/* webpackChunkName: "about" */ "@/views/system/users/index.vue")
  },
  {
    path: "/system/menu",
    name: "menu",
    component: () => import(/* webpackChunkName: "about" */ "@/views/system/menu/index.vue")
  },
  {
    path: "/system/permission",
    name: "permission",
    component: () => import(/* webpackChunkName: "about" */ "@/views/system/permission/index.vue")
  },
  {
    path: "/system/role",
    name: "role",
    component: () => import(/* webpackChunkName: "about" */ "@/views/system/role/index.vue")
  },
  {
    path: "/system/tag",
    name: "tag",
    component: () => import(/* webpackChunkName: "about" */ "@/views/system/tag/index.vue")
  },
  {
    path: "/system/group",
    name: "group",
    component: () => import(/* webpackChunkName: "about" */ "@/views/system/group/index.vue")
  },
  {
    path: "/device",
    name: "device_management",
    component: () => import(/* webpackChunkName: "about" */ "@/views/device/index.vue")
  },
  {
    path: "/device/type",
    name: "device_type",
    component: () => import(/* webpackChunkName: "about" */ "@/views/deviceType/index.vue")
  },
  {
    path: "/media",
    name: "media_management",
    component: () => import(/* webpackChunkName: "about" */ "@/views/media/index.vue")
  },

  {
    path: "/version",
    name: "version",
    component: () =>
        import(/* webpackChunkName: "about" */ "../views/version/index.vue"),
  },

  //代理商管理
  {
    path: "/agentList",
    name: "agentList",
    component: () =>
      import(/* webpackChunkName: "about" */ "@/views/agent/agentList/index.vue"),
    children: [],
  },
  //代理商提现管理
  {
    path: "/agentCash",
    name: "agentCash",
    component: () =>
      import(/* webpackChunkName: "about" */ "@/views/agent/agentCash/index.vue"),
    children: [],
  },

  //商铺管理
  {
    path: "/store",
    name: "store",
    component: () =>
      import(/* webpackChunkName: "about" */ "@/views/store/index.vue"),
    children: [],
  },
  //商户类型
  {
    path: "/store/type",
    name: "storeType",
    component: () =>
      import(/* webpackChunkName: "about" */ "@/views/storeType/index.vue"),
    children: [],
  },
  //定价审核
  {
    path: "/store/price",
    name: "storePrice",
    component: () =>
      import(/* webpackChunkName: "about" */ "@/views/priceEdit/index.vue"),
    children: [],
  },
  //商户提现
  {
    path: "/store/cash",
    name: "storeCash",
    component: () =>
      import(/* webpackChunkName: "about" */ "@/views/store/storeCash/index.vue"),
    children: [],
  },
  //商户负责人
  {
    path: "/store/admin",
    name: "storeAdmin",
    component: () =>
      import(/* webpackChunkName: "about" */ "@/views/store/storeAdmin/index.vue"),
    children: [],
  },
  
  
  {
    path: "/user/users",
    name: "userList",
    component: () => import(/* webpackChunkName: "about" */ "@/views/user/users/index.vue")
  },
  {
    path: "/orderList",
    name: "orderList",
    component: () =>
        import(/* webpackChunkName: "about" */ "../views/order/orderList/index.vue"),
  },
  
  {
    path: "/profitList",
    name: "profitList",
    component: () =>
        import(/* webpackChunkName: "about" */ "../views/order/orderProfit/index.vue"),
  },
  {
    path: "/whiteList",
    name: "whiteList",
    component: () =>
        import(/* webpackChunkName: "about" */ "../views/whiteList/index.vue"),
  },
  {
    path: "/whiteSetList",
    name: "whiteSetList",
    component: () =>
        import(/* webpackChunkName: "about" */ "../views/whiteList/setting.vue"),
  },
  {
    path: "/devops/queue",
    name: "devopsQueue",
    component: () =>
        import(/* webpackChunkName: "about" */ "../views/operate/queue/index.vue"),
  },
  {
    path: "/devops/redis",
    name: "devopsRedis",
    component: () =>
        import(/* webpackChunkName: "about" */ "../views/operate/redis/index.vue"),
  },
  {
    path: "/mine",
    name: "mine",
    component: () =>
        import(/* webpackChunkName: "about" */ "../views/mine/index.vue"),
  },
  {
    path: "/message",
    name: "message",
    component: () =>
        import(/* webpackChunkName: "about" */ "../views/mine/message.vue"),
  },
  {
    path: "/qrcode",
    name: "qrcode",
    component: () =>
        import(/* webpackChunkName: "about" */ "../views/mine/qrcode.vue"),
  },
];

const router = new VueRouter({
  mode:'history',
  base: process.env.BASE_URL,
  routes,
});

export default router;
